import Footer from "components/Footer";
import Navbar from "components/Navbar";
import LoginPage from "pages/public/Auth/LoginPage";
import Complaint from "pages/public/Denuncia";
import HomePage from "pages/public/Home";
import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Contato from "pages/public/Contato";
import PoliticaPrivacidadePage from "pages/public/PoliticaPrivacidade";
import TermosUsoPage from "pages/public/TermosUso";
import TrabalheConoscoPage from "pages/public/TrabalheConosco";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BrowseBlog from "pages/public/Blog/Browse";
import ReadBlog from "pages/public/Blog/Read";

const GuestRoutes = () => {
  // this structure is to get the scroll position
  // a lot of components are using this so dont delete it :)
  const [on_top, setOnTop] = useState(true);
  const [force_on_top, setForceOnTop] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (force_on_top) {
        setOnTop(false);
        return;
      }

      if (window.pageYOffset < 50) {
        setOnTop(true);
      } else {
        setOnTop(false);
      }
    };

    return () => (window.onscroll = null);
  });

  // dark white mode structure
  const [dark_mode, setDarkMode] = useState(false);

  return (
    <>
      <Navbar {...{ on_top, setOnTop, setForceOnTop, dark_mode }} />

      <Routes>
        {/* index */}
        <Route path={"/*"} element={<HomePage {...{on_top}} />} />

        <Route path={"/faca-uma-denuncia"} element={<Complaint />} />
        <Route path={"/contato"} element={<Contato invert_bg_theme={true} />} />
        <Route path={"/trabalhe-conosco"} element={<TrabalheConoscoPage />} />

        <Route
          path={"/politica-de-privacidade"}
          element={<PoliticaPrivacidadePage />}
        />
        <Route path={"/termos-de-uso"} element={<TermosUsoPage />} />

        {/* blog */}
        <Route path={"/blog/navegar"} element={<BrowseBlog />} />
        <Route path={"/blog/ler/:id"} element={<ReadBlog />} />

        {/* auth */}
        <Route path={"/login"} element={<LoginPage />} />

        {/* generic 404 */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <WhatsAppIcon
        onClick={() => {
          window.open("https://wa.me/551732237572", "_blank");
        }}
        style={{
          position: "fixed",
          zIndex: 100,
          bottom: "1rem",
          right: "0.6rem",
          fontSize: "2.8rem",
          borderRadius: "50%",
          color: "var(--background-color0)",
          cursor: "pointer",
          backgroundColor: "#23a654",
          padding: "0.2rem",
        }}
      />

      <Footer {... { dark_mode }} />
    </>
  );
};

export default GuestRoutes;
