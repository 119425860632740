import { Box, Container, Typography } from "@mui/material";
import login_button_style from "components/SpecificStyles/login_button.module.css";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";

import icon1 from "assets/static/home/atuacao1.jpg";
import icon2 from "assets/static/swiper_imgs/bg1.jpg";
import icon3 from "assets/static/swiper_imgs/bg2.jpg";

import AboutUsItem from "./AboutUsItem";

const { default: CustomSection } = require("components/CustomSection");

const items = [
  {
    title: "Vivamus maximus",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis fringilla tellus non semper. Phasellus vitae laoreet nisi, vitae placerat augue.",
    icon: icon1,
  },
  {
    title: "Praesent sodales",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis fringilla tellus non semper. Phasellus vitae laoreet nisi, vitae placerat augue.",
    icon: icon2,
  },
  {
    title: "Orci varius",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis fringilla tellus non semper. Phasellus vitae laoreet nisi, vitae placerat augue.",
    icon: icon3,
  },
];

const Atuacao = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="rgba(var(--accent-color1), 1)"
      id="atuacao"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "10rem 1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            fontFamily: "var(--Freight)",
            color: "var(--text-color1)",
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "10px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "10px auto 0",
            },
          }}
        >
          Áreas de atuação
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          {items.map((item, index) => (
            <AboutUsItem
              key={index}
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          ))}
        </Box>
      </Container>
    </CustomSection>
  );
};

export default Atuacao;
