
import { Box, Container, Typography } from "@mui/material";
import valores from "assets/static/home/valores.jpg";

const Valores = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4rem",
        padding: "8rem 1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: "4rem",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            maxWidth: "40rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "2.6rem",
              color: "var(--text-color0)",
              maxWidth: "60rem",
              fontFamily: "var(--Freight)",
              color: "rgba(var(--accent-color0), 1)",
            }}
          >
            Nossos valores
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: 400,
              fontSize: "1.2rem",
              color: "var(--background-color1)",
              maxWidth: "60rem",
              textAlign: "justify",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            mattis fringilla tellus non semper. Phasellus vitae laoreet nisi,
            vitae placerat.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 400,
              fontSize: "1.2rem",
              color: "var(--background-color1)",
              maxWidth: "60rem",
              textAlign: "justify",
            }}
          >
            Vivamus maximus dolor leo, eu malesuada arcu feugiat nec. Nam sit
            amet enim et urna viverra pretium. Curabitur nibh enim, dapibus in.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 400,
              fontSize: "1.2rem",
              color: "var(--background-color1)",
              maxWidth: "60rem",
              textAlign: "justify",
            }}
          >
            Orci varius natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. 
          </Typography>
        </div>

        <Box
          sx={{
            display: "flex",
            width: {
              xs: "100%",
              md: "30rem",
            },
            position: "relative",
          }}
        >
          <img
            src={valores}
            style={{
              width: "100%",
              objectFit: "cover",
              objectPosition: "left",
              display: "block",
              borderRadius: "0.4rem",
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Valores;
