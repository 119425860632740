import { Box } from "@mui/material";

const AboutUsItem = ({ title, text, icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        gap: "2rem",
        flexDirection: "column",
        // width: "38rem",
        flexBasis: "22rem",
        flexGrow: 1,

        padding: "2rem",
        borderRadius: "1rem",
        alignItems: "center"
      }}
    >
      <Box
        component="img"
        src={icon}
        alt="MVP Icon"
        sx={{
          width: "18rem",
          objectFit: "contain",
          borderRadius: "0.4rem",
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          textAlign: "center"
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: "2.3rem",
            color: "var(--text-color1)",
            fontFamily: "var(--Freight)",
          }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: "1rem",
            textAlign: "center",
            color: "var(--text-color2)",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Box>
    </Box>
  );
};

export default AboutUsItem;
