import { Box, Container } from "@mui/material";
import main_white from "assets/static/logo/logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const footer_links = [
  {
    name: "O Escritório",
    link: "/",
    element_id: "#escritorio",
  },
  {
    name: "Áreas de atuação",
    link: "/",
    element_id: "#atuacao",
  },
  {
    name: "Equipe",
    link: "/",
    element_id: "#equipe",
  },
  {
    name: "Blog",
    link: "/blog/navegar",
    // element_id: "#processos",
  },
];

const social_medias_button_style = {
  fontSize: "2.1rem",
  opacity: "0.6",
  cursor: "pointer",
  color: "var(--text-color0)",
  "&:hover": { opacity: 1 },
};

const social_medias = [
  {
    link: "https://www.instagram.com/enterscience_br/",
    icon: <InstagramIcon sx={social_medias_button_style} />,
  },
  {
    link: "https://www.facebook.com/enterscience",
    icon: <FacebookIcon sx={social_medias_button_style} />,
  },
  {
    link: "https://www.linkedin.com/company/enterscience/",
    icon: <LinkedInIcon sx={social_medias_button_style} />,
  },
  // {
  //   link: "https://twitter.com/enterscience_br",
  //   icon: <TwitterIcon sx={social_medias_button_style} />,
  // }
];

const Footer = ({dark_mode}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [bg_color, set_bg_color] = useState("var(--text-color1)");

  const scrollTo = (element_id) => {
    if ((location.pathname = "/")) {
      const element = document.querySelector(element_id);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/", { replace: true });
      // after navigate to home, scroll to element
      setTimeout(() => {
        const element = document.querySelector(element_id);
        element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  // useEffect(() => {
  //   if (location.pathname === '/') {
  //     set_bg_color("var(--background-color0)")
  //   } else {
  //     set_bg_color("var(--background-color1)")
  //   }
  // }, [location]);

  return (
    <footer style={{ backgroundColor: bg_color, padding: "8rem 0" }}>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        {/* <span
          style={{
            position: "absolute",
            top: "140%",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "0.8rem",
            textAlign: "center",
          }}
        >
          Copyrights © {new Date().getFullYear()} <b>All Rights Reserved by EnterScience Inc</b>. Av. das hortensias, nº 635, Jardim dos seixas, São José do Rio Preto, São Paulo
        </span> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            height: { md: "10rem", xs: "auto" },
            gap: "0.6rem 2rem",
            // transform: { md: "translateX(60%)", xs: "auto" },
          }}
        >
          {footer_links.map((item, index) => (
            <Box
              key={index}
              component="a"
              // href={item.link}
              sx={{
                color: "var(--background-color0)",
                padding: "0.2rem 0",
                cursor: "pointer",
                "&:hover": { filter: "brightness(0.7)" },
              }}
              className="Transition-1"
              onClick={() => {
                if (item.element_id) {
                  scrollTo(item.element_id);
                } else {
                  navigate(item.link, { replace: true });
                }
              }}
            >
              {item.name}
            </Box>
          ))}
        </Box>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            {/* logo */}
            <a href="/">
              <img
                style={{
                  width: "12rem",
                }}
                src={main_white}
                alt="Logotipo da Blueen Open"
              />
            </a>
            <span
              style={{
                color: "var(--background-color2)",
              }}
            >
              by{" "}
              <a
                href="https://enterscience.com.br/"
                target="_blank"
                style={{
                  width: "max-content",
                  fontWeight: 600,
                }}
              >
                EnterScience
              </a>
            </span>
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            {social_medias.map((social_media, index) => (
              <a key={index} href={social_media.link} target={"_blank"}>
                {social_media.icon}
              </a>
            ))}
          </div> */}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
