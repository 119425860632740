import AsideItem from "./AsideItem";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
// import user_icon from "assets/user_icon.svg";
import { useDispatch } from "react-redux";

// icons
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SubjectIcon from '@mui/icons-material/Subject';
import SignpostIcon from '@mui/icons-material/Signpost';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';

const Aside = () => {
  const [shrunk, setShrunk] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleShrunk = (e) => {
    setShrunk(!shrunk);
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: "login", payload: { token: null, user: null } });
    dispatch({ type: "cart_items", payload: null });
    window.location.href = "/";
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShrunk(true);
      } else {
        setShrunk(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: shrunk ? "7.2rem" : "22rem",
        backgroundColor: "#FFF",
        borderRight: "1px solid #E0E0E0",
        paddingTop: "1rem",
        transition: "0.3s ease-in-out",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0 1rem",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            padding: "0.3rem 0.5rem",
            borderRadius: "0.5rem",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={handleShrunk}
        >
          <LastPageRoundedIcon
            style={{
              transform: shrunk ? "scale(1, 1)" : "scale(-1, 1)",
              transition: "0.3s ease-in-out",
            }}
          />
          {shrunk ? null : <span>Encolher</span>}
        </Box>
        <Box
          sx={{
            cursor: "pointer",
            padding: "0.3rem 0.5rem",
            borderRadius: "0.5rem",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={handleLogout}
        >
          <LogoutRoundedIcon />
          {shrunk ? null : <span>Sair</span>}
        </Box>
      </div>

      <hr
        style={{
          width: "100%",
          border: "1px solid #E0E0E0",
          margin: 0,
          marginTop: "1rem",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0",
          width: "100%",
          gap: "0.4rem",
          height: "100%",
          overflowY: "auto",
        }}
      >
        <AsideItem
          icon={DashboardRoundedIcon}
          title="Dashboard"
          href="dashboard"
          {...{ shrunk }}
        />

        <AsideItem
          icon={SignpostIcon}
          title="Blog"
          {...{ shrunk }}
        >
          <AsideItem
            icon={SubjectIcon}
            title="Postagens"
            href="blog/postagens"
            {...{ shrunk }}
          />
          <AsideItem
            icon={LocalOfferIcon}
            title="Tags"
            href="blog/tags"
            {...{ shrunk }}
          />
        </AsideItem>

        <AsideItem
          icon={SecurityRoundedIcon}
          title="Política & Privacidade"
          href="policy-and-terms"
          {...{ shrunk }}
        />
      </div>

      <hr
        style={{
          width: "100%",
          border: "1px solid #E0E0E0",
          margin: 0,
          marginTop: "1rem",
        }}
      />

      <div
        style={{
          display: "flex",
          gap: "0.8rem",
          padding: "0.4rem 0.8rem",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#F5F5F5",
        }}
      >
        <img
          src={"https://picsum.photos/200"}
          alt="Usuário"
          style={{ width: "2.4rem",height: "2.4rem", borderRadius: "50%" }}
        />
        <span style={{ width: "100%" }}>{user.name}</span>
      </div>
    </div>
  );
};

export default Aside;
