import CustomSection from "components/CustomSection";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";

import "./index.css";

// import required modules
import { EffectCreative, Navigation, Pagination } from "swiper";

import SobreNos from "../SobreNos";
import Missao from "../Missao";
import Visao from "../Visao";
import Valores from "../Valores";

const Escritorio = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      id={"escritorio"}
      remove_border
    >
      <Swiper
        style={{
          width: "100vw",
          // height: "100%",
        }}
        grabCursor={true}
        effect={"creative"}
        autoplay={{
          delay: 1000,
        }}
        speed={500}
        slidesPerView={1}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ["-20%", 0, -1],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative, Navigation, Pagination]}
        className="mySwiper3"
      >
        <SwiperSlide>
          <SobreNos />
        </SwiperSlide>
        <SwiperSlide>
          <Missao />
        </SwiperSlide>
        <SwiperSlide>
          <Visao />
        </SwiperSlide>
        <SwiperSlide>
          <Valores />
        </SwiperSlide>
      </Swiper>
    </CustomSection>
  );
};

export default Escritorio;
