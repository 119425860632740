import Landing from "./Landing";
import MVPSection from "./MVPSection";
// import Clientes from "./Clientes";
import FAQPage from "../FAQ";
import Contato from "../Contato";
import AboutUs from "./AboutUs";
import Prevencao from "./Prevencao";
import TimelineContainer from "./TimelineContainer";
import AltaTendencia from "./AltaTendencia";
import Escritorio from "./Escritorio";
import Atuacao from "./Atuacao";
import Equipe from "./Equipe";

const HomePage = ({ on_top }) => {
  return (
    <>
      <Landing {...{ on_top }} />
      <Escritorio />
      <Atuacao />
      <Equipe />
    </>
  );
};

export default HomePage;
