import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import { Typography } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import login_button_style from "components/SpecificStyles/login_button.module.css";
import SwiperCarouselBackground from "./SwiperContainerBackground";
import { Link } from "react-router-dom";
import { FaMailBulk } from "react-icons/fa";

const Landing = ({ on_top }) => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        position: "relative",
        // background: "rgb(76,204,166)",
        // background:
        //   "linear-gradient(90deg, rgba(76,204,166,1) 0%, rgba(66,171,197,1) 100%)",
      }}
      bg_color="black"
      id="home"
      remove_border={true}
    >
      <KeyboardDoubleArrowDownIcon
        onClick={() =>
          window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
          })
        }
        style={{
          position: "absolute",
          bottom: "2rem",
          left: "50%",
          transform: "translateX(-50%)",
          fontSize: on_top ? "3rem" : "0",
          transition: "all 0.5s ease",
          color: "var(--green-0)",
          cursor: "pointer",
        }}
      />
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            zIndex: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "rgba(var(--accent-color0), 1)",
              fontWeight: "bold",
              fontSize: "1.8rem",
              textAlign: "center",
              "&:after": {
                content: "''",
                display: "block",
                marginTop: "10px",
                width: "8rem",
                borderTop: "2px solid rgba(var(--accent-color0), 1)",
                margin: "10px auto 0",
              },
            }}
          >
            Donec est risus ultrices
          </Typography>
          <Typography
            sx={{
              color: "var(--background-color2)",
              fontWeight: "bold",
              fontSize: "5.6rem",
              textAlign: "center",
              marginTop: "1rem",
              fontFamily: "var(--Freight)",
            }}
          >
            Aliquam vitae pulvinar nisl
          </Typography>
          <Typography
            sx={{
              color: "var(--background-color2)",
              fontWeight: "bold",
              fontSize: "1.4rem",
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "2rem",
              maxWidth: "46rem",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
            sollicitudin urna quis faucibus scelerisque.
          </Typography>

          <div
            style={{
              width: "16rem",
              display: "flex",
              position: "relative",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <Link to={"/contato"} className="remove-underline">
              <button
                className={`Transition-1 ${login_button_style.loginButton}`}
                style={{
                  position: "relative",
                  letterSpacing: "0.1rem",
                  perspective: "1500px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaMailBulk
                  style={{
                    marginRight: "0.6rem",
                    display: "flex",
                    zIndex: 2,
                    color: "var(--text-color0)",
                  }}
                  className="Transition-1"
                />
                <span
                  style={{ zIndex: 2, position: "relative", lineHeight: "1.2" }}
                >
                  Fale conosco
                </span>
              </button>
            </Link>
          </div>
        </div>

        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "0",
            opacity: "0.5",
          }}
        >
          <SwiperCarouselBackground />
        </div>
      </Container>
    </CustomSection>
  );
};

export default Landing;
